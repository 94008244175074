import React, { useEffect, useContext } from 'react';
import styles from './LeadGenSuccessPopup.module.scss';
import { Context } from '../../../context/context';
import Image from 'next/image';
import { imagesWEBP } from '../../../constants/webpImages';

const LeadGenSuccessPopup = () => {
    const state = useContext(Context);
    const { hideLeadGenSuccessPopup } = useContext(Context);
  useEffect(() => {
    if (state.openLeadGenSuccessPopup) {
      const timer = setTimeout(() => {
        hideLeadGenSuccessPopup();
      }, 3000); 

      return () => clearTimeout(timer); 
    }
  }, [hideLeadGenSuccessPopup, state.openLeadGenSuccessPopup]);

  if (!state.openLeadGenSuccessPopup) return null;

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <button className={styles.popupClose} onClick={hideLeadGenSuccessPopup}>
          &times;
        </button>
        <div className={styles.popupBody}>
          <Image src={imagesWEBP.successIcon} alt="Success" 
          width={50} height={50}
          className={styles.popupIcon} />
          <h2>Thank you!</h2>
          <p>Our team will reach out to you shortly.</p>
        </div>
      </div>
    </div>
  );
};

export default LeadGenSuccessPopup;

