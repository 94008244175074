export const imagesAVIF = {
  homeIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}Header/avif/Logo.avif`,
  homeAutoAnimationBg: `${process.env.NEXT_PUBLIC_CDN_LINK}ScheduleTestDrive/avif/illustration.avif`,
  homeAutoAnimation: `${process.env.NEXT_PUBLIC_CDN_LINK}ScheduleTestDrive/avif/vehicleimg.avif`,
  homeVehicleGroupImages: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/3vehiclestogether.avif`,
  homeGuarantee: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/avif/guaranteed+.avif`,
  homeAssurance: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/avif/turno_assurance.avif`,
  homeCare: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/avif/turno_care.avif`,
  homeManSec1Desktop: `${process.env.NEXT_PUBLIC_CDN_LINK}MoveAhead/banner-img-1.avif`,
  homeManSec1Mobile: `${process.env.NEXT_PUBLIC_CDN_LINK}MoveAhead/banner-img-1.avif`,
  homeCommercialPoint1: `${process.env.NEXT_PUBLIC_CDN_LINK}BenefitsHomePage/avif/lowest_price.avif`,
  homeCommercialPoint2: `${process.env.NEXT_PUBLIC_CDN_LINK}BenefitsHomePage/avif/best_financing_offers.avif`,
  homeCommercialPoint3: `${process.env.NEXT_PUBLIC_CDN_LINK}BenefitsHomePage/avif/guaranteed_buyback.avif`,
  homeElectricHeroThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}ElectricHeros/homeThumbnail.avif`,
  homeElectricHeroHumanEmoji: `${process.env.NEXT_PUBLIC_CDN_LINK}ElectricHeros/heading+human+emoji+.avif`,
  homeBookingFormVehicles: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/bookform_vehicle.avif`,
  // lead capture
  leadCaptureAutoBenefit: `${process.env.NEXT_PUBLIC_CDN_LINK}BenefitsBooking/2vehiclesloaded+.avif`,
  leadCaptureGuaranteed: `${process.env.NEXT_PUBLIC_CDN_LINK}Booking/refund_bookingamt.avif`,
  leadCaptureCouponAppliedBg: `${process.env.NEXT_PUBLIC_CDN_LINK}Booking/coupon+n+logo/avif/Coupon.avif`,
  // payments
  paymentsSuccesBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/avif/booking_confirmed.avif`,
  paymentsFailureBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/avif/booking_failed.avif`,
  paymentsPendingBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/avif/booking_pendig.avif`,
  paymentsSuccesBannerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/avif/success-banner.avif`,
  paymentsFailureBannerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/avif/Failure-banner.avif`,
  paymentsPendingBannerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/avif/pending-banner.avif`,
  vehicleDiscountTag: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/115.avif`,

  // State Images
  karnatakaState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/karnataka.avif`,
  tamilNaduState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/tamil_nadu.avif`,
  telenganaState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/telengana.avif`,
  maharashtraState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/maharashtra.avif`,
  otherState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/others.avif`,

  // City Images
  bengaluruCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/avif/bengaluru.avif`,
  chennaiCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/avif/chennai.avif`,
  hyderabadCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/avif/hyderabad.avif`,
  puneCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/avif/pune.avif`,
  mumbaiCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/avif/mumbai.avif`,
  delhincrCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/avif/delhincr.avif`,
  othersCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/avif/others.avif`,

  referalBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}Referral/refer.avif`,
  vehicleGuaranteedBuyBack: `${process.env.NEXT_PUBLIC_CDN_LINK}GuaranteedBuyBack/3year_buyback.avif`,
  referralSuccess: `${process.env.NEXT_PUBLIC_CDN_LINK}Referral/ref-confirm-img.avif`,

  // Savings
  savingsVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Piaggio+Corousel+imgs/avif/piaggio_1.avif`,
  savingsVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/avif/mahindra_2.avif`,
  savingsVehicle3: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/avif/osm_6.avif`,
  moneySavingsIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/coins_savings.avif`,
  indiaFlag: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/india_flag.avif`,

  customer1: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customer1.avif`,
  customer2: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customer2.avif`,
  customer3: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customer3.avif`,
  customer4: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customer4.avif`,

  // FAQS
  askQuestion: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/ask_a_ques_banner.avif`,
  askQuestionButton: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/ask_ques.avif`,

  // Customers
  deendayalThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/TurnoLive/avif/deenadayal.avif`,
  girishThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/TurnoLive/avif/girish.avif`,
  kambleThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/TurnoLive/avif/kamble.avif`,
  rudhreshThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/TurnoLive/avif/rudhresh.avif`,

  // About us
  aboutUsBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}AboutUs/fold1/banner-img.avif`,
  aboutUsInvestorStellaris: `${process.env.NEXT_PUBLIC_CDN_LINK}AboutUs/fold4/investors_img1.avif`,
  aboutUsInvestorAvaana: `${process.env.NEXT_PUBLIC_CDN_LINK}AboutUs/fold4/investors_img2.avif`,
  aboutUsCareerBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}AboutUs/last+fold+/joinus_img.avif`,
  playGameBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}PlayGame/game_banner.avif`,

  // Single Page Experiments
  carouselBanner1: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/avif/banner1.avif`,
  carouselBanner2: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/avif/banner2.avif`,
  carouselBanner3: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/avif/banner3.avif`,
  carouselBanner4: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/avif/banner4.avif`,
  carouselBanner5: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/avif/banner5.avif`,
  carouselBanner6: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/avif/banner6.avif`,

  ETRIO: `${process.env.NEXT_PUBLIC_CDN_LINK}thumbnail/vehicle/Etrio.avif`,
  mahindra: `${process.env.NEXT_PUBLIC_CDN_LINK}thumbnail/vehicle/Mahindra.avif`,
  OSM: `${process.env.NEXT_PUBLIC_CDN_LINK}thumbnail/vehicle/Osm.avif`,
  piaggio: `${process.env.NEXT_PUBLIC_CDN_LINK}thumbnail/vehicle/Piaggio.avif`,

  bookingOfferApplied: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/avif/bookin_offer_applied.avif`,

  money_icon: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/avif/money_icon.avif`,

  turno_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/avif/turno_logo.avif`,
  view_360: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/360_view.png`,

  // Product Rating Carousel
  productRating1: `${process.env.NEXT_PUBLIC_CDN_LINK}product_ratings_corousel/avif/PRR1.avif`,
  productRating2: `${process.env.NEXT_PUBLIC_CDN_LINK}product_ratings_corousel/avif/PRR2.avif`,
  productRating3: `${process.env.NEXT_PUBLIC_CDN_LINK}product_ratings_corousel/avif/PRR3.avif`,

  charging: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/charging.avif`,

  // Brand

  // Why Turno
  whyTurno1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whyturno1.avif`,
  whyTurno2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whyturno2.avif`,
  whyTurno3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whyturno3.avif`,
  whyTurno4: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whyturno4.avif`,

  bannerWhyTurno: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/banner.avif`,
  ourPromiseBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/our_promise_banner.avif`,
  aboutTurno: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/aboutturno.avif`,

  // Customer Reviews
  review1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/review1.avif`,
  review2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/review2.avif`,
  review3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/review3.avif`,

  //blogs
  blogsBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blogBanner.avif`,
  blog1: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog1.avif`,
  blog2: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog2.avif`,
  blog3: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog3.avif`,
  blog4: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog4.avif`,
  blog5: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog5.avif`,
  blog6: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog6.avif`,
  blog7: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog7.avif`,
  blog8: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog8.jpg`,

  blog10: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog10.avif`,
  blog11: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog11.avif`,
  blog12: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog12.avif`,
  blog13: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog13.avif`,
  blog14: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog14.avif`,
  blog16: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog16.avif`,

  noImage: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/no-image-blog.avif`,

  // Customer App Banner
  customerAppBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customerapp_banner.avif`,

  // Turno Operational Cities
  turnoOperationalBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/turno_operationalcities.avif`,

  // TCO
  singleCoin: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/coin_electric.avif`,
  doubleCoin: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/coin_cng.avif`,
  tripleCoin: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/coin_diesel.avif`,
  electricVehicle: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/electric_vehicle.avif`,
  dieselVehicle: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/diesel_vehicle.avif`,
  cngVehicle: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/cng_vehicle.avif`,
  turnoBenefits: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/turno_benefits.avif`,

  // emoji

  emoji1: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/avif/1.avif`,
  emoji2: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/avif/2.avif`,
  emoji3: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/avif/3.avif`,
  emoji4: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/avif/4.avif`,
  emoji5: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/avif/5.avif`,
  emoji6: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/avif/6.avif`,

  // Brand Mutual Fund

  benefits1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/benefit1.avif`,
  benefits2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/benefit2.avif`,
  benefits3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/benefit3.avif`,
  expert: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/Expert.avif`,
  whatsappGIF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whatsapp.gif`,
  savingsFlowerGIF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/savingsflower.gif`,
  batteryGIF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/battery.gif`,
  story1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/Turno_Post.mp4`,
  story2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/Turno_Post_2.mp4`,
  story3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/Turno_Post_3.mp4`,

  desktopCarouselBanner1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageBannersDesktop/banner1.avif`,
  desktopCarouselBanner2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageBannersDesktop/banner2.avif`,
  desktopCarouselBanner3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageBannersDesktop/banner3.avif`,

  desktopCarouselBanner4: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageBannersDesktop/banner4.avif`,
  // Dont Buy EV

  disadvantage1: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/fold_1_illustration.avif`,
  disadvantage2: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/fold_2_illustration.avif`,
  disadvantage3: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/fold-3_illustration.avif`,
  batteryBuyBack: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/battery_buyback.png`,
  lowestIR: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/lowest_ir.png`,
  paperLess: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/paperless.png`,
  customerSupport: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/cust_support.png`,
  warningGif: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/error_exclamation.gif`,
  dontBuyEVStory: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/Dont_Buy_Fuel_KA.mp4`,

  // Finance
  chennaiOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/chennai.avif`,
  delhiOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/delhi.avif`,
  gujratOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/gujarat.avif`,
  karnatakaOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/karnataka.avif`,
  maharashtraOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/maharashtra.avif`,
  telenganaOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/telangana.avif`,
  tamilNaduOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/tamil_nadu.avif`,

  // Banner Mobile

  bannerMobileFinance1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/banner1.avif`,
  bannerMobileFinance2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/banner2.avif`,
  bannerMobileFinance3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/banner3.avif`,

  // Banner Desktop
  bannerDesktopFinance1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/banner1.avif`,
  bannerDesktopFinance2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/banner2.avif`,
  bannerDesktopFinance3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/banner3.avif`,

  // Thumbnail Mobile

  thumbnailMobile1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/1.avif`,
  thumbnailMobile2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/2.avif`,
  thumbnailMobile3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/3.avif`,

  // Thumbnail Desktop

  thumbnailDesktop1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/1.avif`,
  thumbnailDesktop2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/2.avif`,
  thumbnailDesktop3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/3.avif`,
  thumbnailDesktop4: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/4.avif`,
  thumbnailDesktop5: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/5.avif`,
  girishCustomer: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/girish.avif`,
  emiCustomerDesktop: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/emi_illustration.avif`,
  emiCustomerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/emi_illustration.avif`,

  // Review Video
  reviewVideo1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo1.mp4`,
  reviewVideo2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo2.mp4`,
  reviewVideo3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo3.mp4`,
  reviewVideo4: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo4.mp4`,
  reviewVideo5: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo5.mp4`,

  googlePlay: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/playstore_img.png`,

  // Brand 3

  bookTestDrive: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/book_TD_banner.avif`,
  allBrands: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/brands.avif`,
  reviewBrand1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/reviews1.avif`,
  reviewBrand2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/reviews2.avif`,
  reviewBrand3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/reviews3.avif`,
  brandSwiper1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/fold1_banner_interestrate.avif`,
  brandSwiper2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/fold1_banner_resale.avif`,
  brandSwiper3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/fold1_banner_TD.avif`,
  ourCustomers: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/our_customers.avif`,
  foldOne: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/fold1_icon.avif`,
  vehicleImage: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/avif/mahindra.avif`,
  // vehicleImage: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/mahindra-charging-ps.gif`,

  brandExp3Video: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/brandExp3.mp4`,

  ghanaBhansali: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/ghalabansali.avif`,
  urgoCapitals: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/urgocapital.avif`,
  shivalik: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/shivaliknbfc.png`,
  idfc: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/idfc.svg`,
  arthmate: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/arthmate.png`,
  dmiFinance: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/dmiFinanceLogo.svg`,
  vcpl: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/vcpl.svg`,

  // web stories

  webStory1: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/1-min.avif`,
  webStory2: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/2-min.avif`,
  webStory3: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/3-min.avif`,
  webStory4: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/4-min.avif`,
  webStory5: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/5-min.avif`,

  // New Design Images
  vehicleLoan: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/EMI_banner.avif`,
  testDrive: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/TD_banner.avif`,

  turnoCallAgent: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/turno+call.webp`,
};
