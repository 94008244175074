import React from "react";

const whatsappPopupActions = {
  HIDE_POPUP: "hideWhatsappPopup",
  SHOW_POPUP: "showWhatsappPopup",
};
const formDrawerActions = {
  HIDE_POPUP: "hideFormPopup",
  SHOW_POPUP: "showFormPopup",
};
const carouselDrawerActions = {
  HIDE_POPUP: "hideCarouselPopup",
  SHOW_POPUP: "showCarouselPopup",
};
const statePopupActions = {
  HIDE_POPUP: "hideStatePopup",
  SHOW_POPUP: "showStatePopup",
};
const evConsultPopupActions = {
  HIDE_POPUP: "hideEVConsultPopup",
  SHOW_POPUP: "showEVConsultPopup",
};
const cityPopupActions = {
  HIDE_POPUP: "hideCityPopup",
  SHOW_POPUP: "showCityPopup",
};
const discountPopupActions = {
  HIDE_POPUP: "hideDiscountPopup",
  SHOW_POPUP: "showDiscountPopup",
};

const snackbarActions = {
  SHOW_SNACKBAR: "showSnackbar",
  HIDE_SNACKBAR: "hideSnackbar",
};

const leadGenSuccessPopupActions = {
  HIDE_POPUP: "hideLeadGenPopup",
  SHOW_POPUP: "showLeadGenPopup",
};
const changeCityActions = {
  CHANGE_CITY: "changecity",
};

const changeStateActions = {
  CHANGE_STATE: "changeState",
};
const changeVehicleBrand = {
  CHANGE_VEHICLE_BRAND: "changeVehicleBrand",
};

const changeVehicleUuid = {
  CHANGE_VEHICLE_UUID: "changeVehicleUuid",
};
const changeVerifiedStatusActions = {
  CHANGE_VERIFIED_STATUS: "changeVerifiedStatus",
};
const otpComponentActions = {
  SHOW_OTP_COMPONENT: "showOtpComponent",
  HIDE_OTP_COMPONENT: "hideOtpComponent",
};
const posthogActions = {
  UPDATE_POSTHOG_VALUES: "updatePosthogValues",
};

const otpValidationActions = {
  VALIDATE_OTP: "showOtpValidation",
  HIDE_VALIDATE_OTP: "hideOtpValidation",
};

const askQuestionsActions = {
  ASK_QUESTION: "showAskQuestion",
  HIDE_ASK_QUESTION: "hideAskQuestion",
};
const faqCustomerIdActions = {
  UPDATE_CUSTOMER_ID: "updateCustomerId",
};
const decentroVerificationActions = {
  UPDATE_VERIFICATION_STATUS: "updateVerificationStatus",
};
const customerConsentActions = {
  UPDATE_CUSTOMER_CONSENT: "updateCustomerConsent",
};

const emiPriceValueActions = {
  UPDATE_EMI_PRICE_VALUE: "updateEmiPriceValue",
};

const storyFormActions = {
  SHOW_STORY_FORM: "showStoryForm",
  HIDE_STORY_FORM: "hideStoryForm",
};

const dontBuyEvActions = {
  SHOW_DRAWER: "showDrawer",
  HIDE_DRAWER: "hideDrawer",
};

const reviewStoriesActions = {
  SHOW_DRAWER: "showReviewStoryDrawer",
  HIDE_DRAWER: "hideReviewStoryDrawer",
};

const testDrivePopupActions = {
  SHOW_DRAWER: "showTestDriveDrawer",
  HIDE_DRAWER: "hideTestDriveDrawer",
};

const headerDrawerActions = {
  SHOW_HEADER: "showHeaderDrawer",
  HIDE_HEADER: "hideHeaderDrawer",
};

const showVehicleListActions = {
  SHOW_VEHICLE_LIST: "showVehicleListDrawer",
  HIDE_VEHICLE_LIST: "hideVehicleListDrawer",
};

const showConnectDrawerActions = {
  SHOW_CONNECT_DRAWER: "showConnectDrawer",
  HIDE_CONNECT_DRAWER: "hideConnectDrawer",
};

const showLoanPopupActions = {
  SHOW_LOAN_POPUP: "showLoanPopup",
  HIDE_LOAN_POPUP: "hideLoanPopup",
};

const updateTestDriveActions = {
  UPDATE_TEST_DRIVE: "updateTestDriveDetails",
};

// search Value actions
const searchValueActions = {
  SEARCH_QUERY: "searchQuery",
};

const selectedVehicleInfoActions = {
  SELECTED_VEHICLE: "updateSelectedVehicle",
};

const updateUserQlDataActions = {
  UPDATE_USER_QL_DATA: "updateUserQlData",
};

const initialState = {
  serviceType: "Book Vehicle",
  showWhatsappPopupValue: false,
  showFormDrawerPopup: false,
  showCarouselDrawerpopup: false,
  carouselDrawerTrigger: "",
  showEVConsultationPopup: false,
  showStoryFormValue: false,
  showReviewStoryDrawerValue: false,
  showTestDriveDrawerValue: false,
  // imageName: "piaggioThumbnail",
  connect_type: "",
  showStatePopupValue: false,
  selectedStateRoute: "",
  showCityPopupValue: false,
  showDiscountPopupValue: false,
  selectedCity: "India",
  selectedState: "Others",
  vehicleBrand: "piaggio",
  vehicleUuid: "",
  message: "",
  openSnackbar: false,
  openLeadGenSuccessPopup: false,
  severityType: "success",
  leadVerifiedStatus: false,
  showOtpModule: false,
  posthogValues: "",
  isOTPValidated: false,
  askQuestionPopup: false,
  customerId: 1,
  decentroVerificationStatus: false,
  customerConsent: false,
  emiPriceValue: "",
  dontBuyEvDrawer: false,
  showHeaderValue: false,
  showVehicleList: false,
  showVehicleListValue: {},
  showConnectDrawerValue: false,
  showLoanPopupValue: false,
  loanPopupTrigger: {},
  testDriveDetails: {
    vehicleId: "",
    date: "null",
    timeSlot: "",
    location: "",
    state: "none",
    city: "none",
    address: "",
  },

  // search value
  searchValue: "",

  //Whatsapp Trigger
  whatsAppConnectDrawerType: "",
  selectedVehicleInfo: {},

  userQlData: {
    mobile: "",
    isThreeWheelerReq: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    // Whatsapp Popup
    case whatsappPopupActions.HIDE_POPUP: {
      const newstate = {
        ...state,
        showWhatsappPopupValue: false,
      };
      return newstate;
    }
    case whatsappPopupActions.SHOW_POPUP: {
      const newstate = {
        ...state,
        connect_type: action.obj.connect_type,
        showWhatsappPopupValue: true,
      };
      return newstate;
    }
    // Test Drive Popup
    case testDrivePopupActions.HIDE_DRAWER: {
      const newstate = {
        ...state,
        showTestDriveDrawerValue: false,
      };
      return newstate;
    }
    case testDrivePopupActions.SHOW_DRAWER: {
      const newstate = {
        ...state,
        showTestDriveDrawerValue: true,
      };

      return newstate;
    }
    // EV Consult Popup
    case evConsultPopupActions.HIDE_POPUP: {
      const newstate = {
        ...state,
        showEVConsultationPopup: false,
      };
      return newstate;
    }
    case evConsultPopupActions.SHOW_POPUP: {
      const newstate = {
        ...state,
        showEVConsultationPopup: true,
      };
      return newstate;
    }

    // Connect Drawer
    case showConnectDrawerActions.HIDE_CONNECT_DRAWER: {
      const newstate = {
        ...state,
        showConnectDrawerValue: false,
      };
      return newstate;
    }
    case showConnectDrawerActions.SHOW_CONNECT_DRAWER: {
      const newstate = {
        ...state,
        whatsAppConnectDrawerType: action.obj.whatsAppConnectDrawerType,
        showConnectDrawerValue: true,
      };
      return newstate;
    }

    // Loan Popup
    case showLoanPopupActions.HIDE_LOAN_POPUP: {
      const newstate = {
        ...state,
        showLoanPopupValue: false,
      };
      return newstate;
    }
    case showLoanPopupActions.SHOW_LOAN_POPUP: {
      const newstate = {
        ...state,
        loanPopupTrigger: action.obj.loanPopupTrigger,
        showLoanPopupValue: true,
      };
      return newstate;
    }

    // Dont Buy Ev Popup

    case dontBuyEvActions.HIDE_DRAWER: {
      const newstate = {
        ...state,
        dontBuyEvDrawer: false,
      };
      return newstate;
    }
    case dontBuyEvActions.SHOW_DRAWER: {
      const newstate = {
        ...state,
        dontBuyEvDrawer: true,
      };
      return newstate;
    }

    // Review Story Drawer

    case reviewStoriesActions.SHOW_DRAWER: {
      const newstate = {
        ...state,
        showReviewStoryDrawerValue: false,
      };
      return newstate;
    }
    case reviewStoriesActions.HIDE_DRAWER: {
      const newstate = {
        ...state,
        showReviewStoryDrawerValue: false,
      };
      return newstate;
    }
    case formDrawerActions.HIDE_POPUP: {
      const newstate = {
        ...state,
        showFormDrawerPopup: false,
      };
      return newstate;
    }
    case formDrawerActions.SHOW_POPUP: {
      const newstate = {
        ...state,
        // imageName: action.obj.imageName,
        serviceType: action.obj.serviceType,
        showFormDrawerPopup: true,
      };
      return newstate;
    }
    case carouselDrawerActions.HIDE_POPUP: {
      const newstate = {
        ...state,
        showCarouselDrawerpopup: false,
      };
      return newstate;
    }
    case carouselDrawerActions.SHOW_POPUP: {
      const newstate = {
        ...state,
        // imageName: action.obj.imageName,
        // serviceType: action.obj.serviceType,
        carouselDrawerTrigger: action.obj.carouselDrawerTrigger,
        showCarouselDrawerpopup: true,
      };
      return newstate;
    }
    // State Popup
    case statePopupActions.HIDE_POPUP: {
      const newstate = {
        ...state,
        showStatePopupValue: false,
        selectedStateRoute: action.obj.selectedStateRoute,
      };
      return newstate;
    }
    case statePopupActions.SHOW_POPUP: {
      const newstate = {
        ...state,
        showStatePopupValue: true,
        selectedStateRoute: action.obj.selectedStateRoute,
      };
      return newstate;
    }

    // City Popup
    case cityPopupActions.HIDE_POPUP: {
      const newstate = {
        ...state,
        showCityPopupValue: false,
      };
      return newstate;
    }
    case cityPopupActions.SHOW_POPUP: {
      const newstate = {
        ...state,
        showCityPopupValue: true,
      };
      return newstate;
    }
    // Discount Popup
    case discountPopupActions.HIDE_POPUP: {
      const newstate = {
        ...state,
        showDiscountPopupValue: false,
      };
      return newstate;
    }
    case discountPopupActions.SHOW_POPUP: {
      const newstate = {
        ...state,
        showDiscountPopupValue: true,
      };
      return newstate;
    }
    // Snackbar
    case snackbarActions.SHOW_SNACKBAR: {
      const newstate = {
        ...state,
        openSnackbar: true,
        message: action.obj.message,
        severityType: action.obj.severityType,
      };
      return newstate;
    }
    case snackbarActions.HIDE_SNACKBAR: {
      const newstate = {
        ...state,
        openSnackbar: false,
      };
      return newstate;
    }

    // Lead Gen Success Popup
    case leadGenSuccessPopupActions.HIDE_POPUP: {
      const newstate = {
        ...state,
        openLeadGenSuccessPopup: false,
      };
      return newstate;
    }
    case leadGenSuccessPopupActions.SHOW_POPUP: {
      const newstate = {
        ...state,
        openLeadGenSuccessPopup: true,
      };
      return newstate;
    }
    // Change State
    case changeStateActions.CHANGE_STATE: {
      const newstate = {
        ...state,
        selectedState: action.obj.selectedState,
      };
      return newstate;
    }
    // Change City
    case changeCityActions.CHANGE_CITY: {
      const newstate = {
        ...state,
        selectedCity: action.obj.selectedCity,
      };
      return newstate;
    }
    // Change Vehicle Brand
    case changeVehicleBrand.CHANGE_VEHICLE_BRAND: {
      const newstate = {
        ...state,
        vehicleBrand: action.obj.vehicleBrand,
      };
      return newstate;
    }
    // Change Vehicle UUID
    case changeVehicleUuid.CHANGE_VEHICLE_UUID: {
      const newstate = {
        ...state,
        vehicleUuid: action.obj.vehicleUuid,
      };
      return newstate;
    }
    // Lead Verified Status

    case changeVerifiedStatusActions.CHANGE_VERIFIED_STATUS: {
      const newstate = {
        ...state,
        leadVerifiedStatus: true,
      };
      return newstate;
    }
    // Show OTP Component
    case otpComponentActions.SHOW_OTP_COMPONENT: {
      const newState = {
        ...state,
        showOtpModule: true,
      };
      return newState;
    }
    // Hide OTP Component
    case otpComponentActions.HIDE_OTP_COMPONENT: {
      const newState = {
        ...state,
        showOtpModule: false,
      };
      return newState;
    }
    // Posthog Values
    case posthogActions.UPDATE_POSTHOG_VALUES: {
      const newState = {
        ...state,
        posthogValues: action.obj.posthogValues,
      };
      return newState;
    }

    case otpValidationActions.VALIDATE_OTP: {
      const newState = {
        ...state,
        isOTPValidated: true,
      };
      return newState;
    }

    case otpValidationActions.HIDE_VALIDATE_OTP: {
      const newState = {
        ...state,
        isOTPValidated: false,
      };
      return newState;
    }
    case askQuestionsActions.ASK_QUESTION: {
      const newState = {
        ...state,
        askQuestionPopup: true,
      };
      return newState;
    }

    case askQuestionsActions.HIDE_ASK_QUESTION: {
      const newState = {
        ...state,
        askQuestionPopup: false,
      };
      return newState;
    }
    case faqCustomerIdActions.UPDATE_CUSTOMER_ID: {
      const newState = {
        ...state,
        customerId: action.obj.customerId,
      };
      return newState;
    }

    case decentroVerificationActions.UPDATE_VERIFICATION_STATUS: {
      const newState = {
        ...state,
        decentroVerificationStatus: true,
      };
      return newState;
    }

    case customerConsentActions.UPDATE_CUSTOMER_CONSENT: {
      const newState = {
        ...state,
        customerConsent: true,
      };
      return newState;
    }

    case emiPriceValueActions.UPDATE_EMI_PRICE_VALUE: {
      const newState = {
        ...state,
        emiPriceValue: action.obj.emiPriceValue,
      };
      return newState;
    }

    // Story Form

    case storyFormActions.SHOW_STORY_FORM: {
      const newState = {
        ...state,
        showStoryFormValue: true,
      };
      return newState;
    }

    case storyFormActions.HIDE_STORY_FORM: {
      const newState = {
        ...state,
        showStoryFormValue: false,
      };
      return newState;
    }

    case headerDrawerActions.SHOW_HEADER: {
      const newState = {
        ...state,
        showHeaderValue: true,
      };
      return newState;
    }

    case headerDrawerActions.HIDE_HEADER: {
      const newState = {
        ...state,
        showHeaderValue: false,
      };
      return newState;
    }

    case showVehicleListActions.SHOW_VEHICLE_LIST: {
      const newState = {
        ...state,
        showVehicleList: true,
        showVehicleListValue: action.obj.showVehicleListValue,
      };
      return newState;
    }

    case showVehicleListActions.HIDE_VEHICLE_LIST: {
      const newState = {
        ...state,
        showVehicleList: false,
      };
      return newState;
    }

    // Test Drive Details

    case updateTestDriveActions.UPDATE_TEST_DRIVE: {
      const newState = {
        ...state,
        testDriveDetails: action.obj.testDriveDetails,
      };
      return newState;
    }

    //search vehicle

    case searchValueActions.SEARCH_QUERY: {
      const newState = {
        ...state,
        searchValue: action.obj.newSearchValue,
      };
      return newState;
    }

    case selectedVehicleInfoActions.SELECTED_VEHICLE: {
      const newState = {
        ...state,
        selectedVehicleInfo: action.obj.selectedVehicleInfo,
      };
      return newState;
    }

    // Test Drive Details

    case updateUserQlDataActions.UPDATE_USER_QL_DATA: {
      const newState = {
        ...state,
        userQlData: action.obj.userQlData,
      };
      return newState;
    }
    default:
      return state;
  }
};

export const Context = React.createContext();

export const WhatsAppPopupProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = {
    decentroVerificationStatus: state.decentroVerificationStatus,
    customerConsent: state.customerConsent,
    showCarouselDrawerpopup: state.showCarouselDrawerpopup,
    carouselDrawerTrigger: state.carouselDrawerTrigger,
    showFormDrawerPopup: state.showFormDrawerPopup,
    showStoryFormValue: state.showStoryFormValue,
    // imageName: state.imageName,
    showTestDriveDrawerValue: state.showTestDriveDrawerValue,
    showReviewStoryDrawerValue: state.showReviewStoryDrawerValue,
    serviceType: state.serviceType,
    showWhatsappPopupValue: state.showWhatsappPopupValue,
    showStatePopupValue: state.showStatePopupValue,
    selectedStateRoute: state.selectedStateRoute,
    showCityPopupValue: state.showCityPopupValue,
    connect_type: state.connect_type,
    showDiscountPopupValue: state.showDiscountPopupValue,
    selectedState: state.selectedState,
    showEVConsultationPopup: state.showEVConsultationPopup,
    selectedCity: state.selectedCity,
    vehicleBrand: state.vehicleBrand,
    vehicleUuid: state.vehicleUuid,
    message: state.message,
    openSnackbar: state.openSnackbar,
    openLeadGenSuccessPopup: state.openLeadGenSuccessPopup,
    severityType: state.severityType,
    leadVerifiedStatus: state.leadVerifiedStatus,
    showOtpModule: state.showOtpModule,
    posthogValues: state.posthogValues,
    isOTPValidated: state.isOTPValidated,
    askQuestionPopup: state.askQuestionPopup,
    customerId: state.customerId,
    emiPriceValue: state.emiPriceValue,
    dontBuyEvDrawer: state.dontBuyEvDrawer,
    showHeaderValue: state.showHeaderValue,
    showVehicleList: state.showVehicleList,
    showVehicleListValue: state.showVehicleListValue,
    showConnectDrawerValue: state.showConnectDrawerValue,
    showLoanPopupValue: state.showLoanPopupValue,
    loanPopupTrigger: state.loanPopupTrigger,
    testDriveDetails: state.testDriveDetails,
    searchValue: state.searchValue,
    whatsAppConnectDrawerType: state.whatsAppConnectDrawerType,
    selectedVehicleInfo: state.selectedVehicleInfo,
    userQlData: state.userQlData,
    // Whatsapp Popup
    hideWhatsappPopup: () => {
      const obj = {
        showWhatsappPopupValue: false,
      };
      dispatch({ type: whatsappPopupActions.HIDE_POPUP, obj });
    },
    showWhatsappPopup: (connect_type) => {
      const obj = {
        showWhatsappPopupValue: true,
        connect_type: connect_type,
      };
      dispatch({ type: whatsappPopupActions.SHOW_POPUP, obj });
    },

    // Test drive Popup
    hideTestDriveDrawer: () => {
      const obj = {
        showTestDriveDrawerValue: false,
      };
      dispatch({ type: testDrivePopupActions.HIDE_DRAWER, obj });
    },
    showTestDriveDrawer: () => {
      const obj = {
        showTestDriveDrawerValue: true,
      };
      dispatch({ type: testDrivePopupActions.SHOW_DRAWER, obj });
    },

    // Don't buy ev  drawer
    hideDrawer: () => {
      const obj = {
        dontBuyEvDrawer: false,
      };
      dispatch({ type: dontBuyEvActions.HIDE_DRAWER, obj });
    },
    showDrawer: (connect_type) => {
      const obj = {
        dontBuyEvDrawer: true,
      };
      dispatch({ type: dontBuyEvActions.SHOW_DRAWER, obj });
    },

    // EV Consult Popup
    hideEVConsultPopup: () => {
      const obj = {
        showEVConsultationPopup: false,
      };
      dispatch({ type: evConsultPopupActions.HIDE_POPUP, obj });
    },
    showEVConsultPopup: () => {
      const obj = {
        showEVConsultationPopup: true,
      };
      dispatch({ type: evConsultPopupActions.SHOW_POPUP, obj });
    },

    // Connect Drawer
    hideConnectDrawer: () => {
      const obj = {
        showConnectDrawerValue: false,
      };
      dispatch({ type: showConnectDrawerActions.HIDE_CONNECT_DRAWER, obj });
    },
    showConnectDrawer: (whatsAppConnectDrawerType) => {
      const obj = {
        showConnectDrawerValue: true,
        whatsAppConnectDrawerType: whatsAppConnectDrawerType,
      };
      dispatch({ type: showConnectDrawerActions.SHOW_CONNECT_DRAWER, obj });
    },
    // Loan Popup
    showLoanPopup: (loanPopupTrigger) => {
      const obj = {
        showLoanPopupValue: true,
        loanPopupTrigger: loanPopupTrigger,
      };
      dispatch({ type: showLoanPopupActions.SHOW_LOAN_POPUP, obj });
    },
    hideLoanPopup: () => {
      const obj = {
        showLoanPopupValue: false,
      };
      dispatch({ type: showLoanPopupActions.HIDE_LOAN_POPUP, obj });
    },

    // Form Drawer Popup
    hideFormPopup: () => {
      const obj = {
        showFormDrawerPopup: false,
      };
      dispatch({ type: formDrawerActions.HIDE_POPUP, obj });
    },
    showFormPopup: (serviceType) => {
      const obj = {
        // imageName: imageName,
        serviceType: serviceType,
        showFormDrawerPopup: true,
      };

      dispatch({ type: formDrawerActions.SHOW_POPUP, obj });
    },
    // Carousel Drawer Popup
    hideCarouselpopup: () => {
      const obj = {
        showCarouselDrawerpopup: false,
      };
      dispatch({ type: carouselDrawerActions.HIDE_POPUP, obj });
    },
    showCarouselPopup: (carouselDrawerTrigger) => {
      const obj = {
        carouselDrawerTrigger,
        showCarouselDrawerpopup: true,
      };
      dispatch({ type: carouselDrawerActions.SHOW_POPUP, obj });
    },
    // State Popup
    hideStatePopup: (route) => {
      const obj = {
        showStatePopupValue: false,
        selectedStateRoute: route ?? "",
      };
      dispatch({ type: statePopupActions.HIDE_POPUP, obj });
    },
    showStatePopup: (route) => {
      const obj = {
        showStatePopupValue: true,
        selectedStateRoute: route ?? "",
      };
      dispatch({ type: statePopupActions.SHOW_POPUP, obj });
    },
    // City Popup
    hideCityPopup: () => {
      const obj = {
        showCityPopupValue: false,
      };
      dispatch({ type: cityPopupActions.HIDE_POPUP, obj });
    },
    showCityPopup: () => {
      const obj = {
        showCityPopupValue: true,
      };
      dispatch({ type: cityPopupActions.SHOW_POPUP, obj });
    },
    // Discount Popup
    hideDiscountPopup: () => {
      const obj = {
        showDiscountPopupValue: false,
      };
      dispatch({ type: discountPopupActions.HIDE_POPUP, obj });
    },
    showDiscountPopup: () => {
      const obj = {
        showDiscountPopupValue: true,
      };
      dispatch({ type: discountPopupActions.SHOW_POPUP, obj });
    },

    // Review Story Drawer

    showReviewStoryDrawer: () => {
      const obj = {
        showReviewStoryDrawerValue: true,
      };
      dispatch({ type: reviewStoriesActions.SHOW_DRAWER, obj });
    },

    hideReviewStoryDrawer: () => {
      const obj = {
        showReviewStoryDrawerValue: false,
      };
      dispatch({ type: reviewStoriesActions.HIDE_DRAWER, obj });
    },
    // Snackbar
    showSnackbar: (message, severity) => {
      const obj = {
        message,
        severityType: severity,
      };
      dispatch({ type: snackbarActions.SHOW_SNACKBAR, obj });
    },
    hideSnackbar: () => {
      dispatch({ type: snackbarActions.HIDE_SNACKBAR });
    },

    // Lead Gen Success Popup
    showLeadGenSuccessPopup: () => {
      dispatch({ type: leadGenSuccessPopupActions.SHOW_POPUP });
    },
    hideLeadGenSuccessPopup: () => {
      dispatch({ type: leadGenSuccessPopupActions.HIDE_POPUP });
    },

    // State Selection
    changeState: (state) => {
      const obj = {
        selectedState: state,
      };
      dispatch({ type: changeStateActions.CHANGE_STATE, obj });
    },
    // City Selection
    changeCity: (city) => {
      const obj = {
        selectedCity: city,
      };
      dispatch({ type: changeCityActions.CHANGE_CITY, obj });
    },
    // Vehicle Brand Selection
    changeVehicleBrand: (brand) => {
      const obj = {
        vehicleBrand: brand,
      };
      dispatch({ type: changeVehicleBrand.CHANGE_VEHICLE_BRAND, obj });
    },
    // Vehicle Brand Selection
    changeVehicleUuid: (uuid) => {
      const obj = {
        vehicleUuid: uuid,
      };
      dispatch({ type: changeVehicleUuid.CHANGE_VEHICLE_UUID, obj });
    },
    // Lead Verified Status
    changeVerifiedStatus: () => {
      const obj = {
        leadVerifiedStatus: true,
      };
      dispatch({
        type: changeVerifiedStatusActions.CHANGE_VERIFIED_STATUS,
        obj,
      });
    },
    // Show OTP Component
    showOtpComponent: () => {
      const obj = {
        showOtpModule: true,
      };
      dispatch({ type: otpComponentActions.SHOW_OTP_COMPONENT, obj });
    },
    // Hide OTP Component
    hideOtpComponent: () => {
      const obj = {
        showOtpModule: false,
      };
      dispatch({ type: otpComponentActions.HIDE_OTP_COMPONENT, obj });
    },
    // Posthog Values
    updatePosthogValues: (posthogValues) => {
      const obj = {
        posthogValues: posthogValues,
      };
      dispatch({ type: posthogActions.UPDATE_POSTHOG_VALUES, obj });
    },

    // Otp Validation activation
    showOtpValidation: (value) => {
      const obj = {
        isOTPValidated: value,
      };
      dispatch({ type: otpValidationActions.VALIDATE_OTP, obj });
    },
    hideOtpValidation: (connect_type) => {
      const obj = {
        isOTPValidated: false,
      };
      dispatch({ type: otpValidationActions.HIDE_VALIDATE_OTP, obj });
    },
    showAskQuestionPopup: () => {
      const obj = {
        askQuestionPopup: true,
      };
      dispatch({ type: askQuestionsActions.ASK_QUESTION, obj });
    },

    hideAskQuestionPopup: () => {
      const obj = {
        askQuestionPopup: false,
      };
      dispatch({ type: askQuestionsActions.HIDE_ASK_QUESTION, obj });
    },
    updateCustomerId: (customerId) => {
      const obj = {
        customerId,
      };
      dispatch({ type: faqCustomerIdActions.UPDATE_CUSTOMER_ID, obj });
    },
    updateVerificationStatus: () => {
      const obj = {
        decentroVerificationStatus: true,
      };
      dispatch({
        type: decentroVerificationActions.UPDATE_VERIFICATION_STATUS,
        obj,
      });
    },

    updateCustomerConsent: () => {
      const obj = {
        customerConsent: true,
      };
      dispatch({ type: customerConsentActions.UPDATE_CUSTOMER_CONSENT, obj });
    },

    updateEmiPriceValue: (value) => {
      const obj = {
        emiPriceValue: value,
      };
      dispatch({ type: emiPriceValueActions.UPDATE_EMI_PRICE_VALUE, obj });
    },

    showStoryForm: () => {
      const obj = {
        showStoryFormValue: true,
      };
      dispatch({ type: storyFormActions.SHOW_STORY_FORM, obj });
    },
    hideStoryForm: () => {
      const obj = {
        showStoryFormValue: false,
      };
      dispatch({ type: storyFormActions.HIDE_STORY_FORM, obj });
    },

    showHeader: () => {
      const obj = {
        showHeaderValue: true,
      };
      dispatch({ type: headerDrawerActions.SHOW_HEADER, obj });
    },
    hideHeader: () => {
      const obj = {
        showStoryFormValue: false,
      };
      dispatch({ type: headerDrawerActions.HIDE_HEADER, obj });
    },

    showVehicleListDrawer: (showVehicleListValue) => {
      const obj = {
        showVehicleList: true,
        showVehicleListValue,
      };
      dispatch({ type: showVehicleListActions.SHOW_VEHICLE_LIST, obj });
    },
    hideVehicleListDrawer: () => {
      const obj = {
        showVehicleList: false,
      };
      dispatch({ type: showVehicleListActions.HIDE_VEHICLE_LIST, obj });
    },

    updateTestDriveDetails: (testDriveDetails) => {
      const obj = {
        testDriveDetails: testDriveDetails,
      };
      dispatch({
        type: updateTestDriveActions.UPDATE_TEST_DRIVE,
        obj,
      });
    },
    // search value

    searchQuery: (searchValue) => {
      const obj = {
        newSearchValue: searchValue,
      };
      dispatch({
        type: searchValueActions.SEARCH_QUERY,
        obj,
      });
    },

    updateSelectedVehicle: (selectedVehicleInfo) => {
      const obj = {
        selectedVehicleInfo: selectedVehicleInfo,
      };

      dispatch({
        type: selectedVehicleInfoActions.SELECTED_VEHICLE,
        obj,
      });
    },

    updateUserQlData: (userQlData) => {
      const obj = {
        userQlData: userQlData,
      };
      dispatch({
        type: updateUserQlDataActions.UPDATE_USER_QL_DATA,
        obj,
      });
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
