export const imagesPNG = {
  homeIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}Header/png/Logo.png`,
  homeAutoAnimationBg: `${process.env.NEXT_PUBLIC_CDN_LINK}ScheduleTestDrive/png/illustration.png`,
  homeAutoAnimation: `${process.env.NEXT_PUBLIC_CDN_LINK}ScheduleTestDrive/png/vehicleimg.png`,
  homeVehicleGroupImages: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/3vehiclestogether.png`,
  homeGuarantee: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/png/guaranteed+.png`,
  homeAssurance: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/png/turno_assurance.png`,
  homeCare: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/png/turno_care.png`,
  homeManSec1Desktop: `${process.env.NEXT_PUBLIC_CDN_LINK}MoveAhead/banner-img-1.jpg`,
  homeManSec1Mobile: `${process.env.NEXT_PUBLIC_CDN_LINK}MoveAhead/banner-img-1.jpg`,
  homeCommercialPoint1: `${process.env.NEXT_PUBLIC_CDN_LINK}BenefitsHomePage/png/lowest_price.png`,
  homeCommercialPoint2: `${process.env.NEXT_PUBLIC_CDN_LINK}BenefitsHomePage/png/best_financing_offers.png`,
  homeCommercialPoint3: `${process.env.NEXT_PUBLIC_CDN_LINK}BenefitsHomePage/png/guaranteed_buyback.png`,
  homeElectricHeroThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}ElectricHeros/homeThumbnail.webp`,
  homeElectricHeroHumanEmoji: `${process.env.NEXT_PUBLIC_CDN_LINK}ElectricHeros/heading+human+emoji+.png`,
  homeBookingFormVehicles: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/bookform_vehicle.png`,
  // lead capture
  leadCaptureAutoBenefit: `${process.env.NEXT_PUBLIC_CDN_LINK}BenefitsBooking/2vehiclesloaded+.png`,
  leadCaptureGuaranteed: `${process.env.NEXT_PUBLIC_CDN_LINK}Booking/refund_bookingamt.png`,
  leadCaptureCouponAppliedBg: `${process.env.NEXT_PUBLIC_CDN_LINK}Booking/coupon+n+logo/png/Coupon.png`,

  //Savings

  savingsVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Piaggio+Corousel+imgs/compressed/piaggio_1.jpg`,
  savingsVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_2.jpg`,
  savingsVehicle3: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/OSM+Corousel+imgs/compressed/osm_6.jpg`,
  moneySavingsIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/coins_savings.png`,
  indiaFlag: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/india_flag.png`,
  customer1: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customer1.png`,
  customer2: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customer2.png`,
  customer3: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customer3.png`,
  customer4: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customer4.png`,

  // payments
  paymentsSuccesBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/png/booking_confirmed.png`,
  paymentsFailureBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/png/booking_failed.png`,
  paymentsPendingBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/png/booking_pending.png`,
  paymentsSuccesBannerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/png/success-banner.png`,
  paymentsFailureBannerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/png/Failure-banner.png`,
  paymentsPendingBannerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}Payment/png/pending-banner.png`,
  vehicleDiscountTag: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/115.png`,

  // State Images
  karnatakaState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/karnataka.png`,
  tamilNaduState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/tamil_nadu.png`,
  telenganaState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/telengana.png`,
  maharashtraState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/maharashtra.png`,
  otherState: `${process.env.NEXT_PUBLIC_CDN_LINK}states/others.png`,

  // City Images
  bengaluruCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/png/bengaluru.png`,
  chennaiCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/png/chennai.png`,
  hyderabadCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/png/hyderabad.png`,
  puneCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/png/pune.png`,
  mumbaiCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/png/mumbai.png`,
  delhincrCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/png/delhincr.png`,
  othersCity: `${process.env.NEXT_PUBLIC_CDN_LINK}states/city/png/others.png`,

  referalBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}Referral/refer.webp`,
  vehicleGuaranteedBuyBack: `${process.env.NEXT_PUBLIC_CDN_LINK}GuaranteedBuyBack/3year_buyback.png`,
  referralSuccess: `${process.env.NEXT_PUBLIC_CDN_LINK}Referral/ref-confirm-img.png`,

  // About us
  aboutUsBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}AboutUs/fold1/banner-img.jpg`,
  aboutUsInvestorStellaris: `${process.env.NEXT_PUBLIC_CDN_LINK}AboutUs/fold4/investors_img1.png`,
  aboutUsInvestorAvaana: `${process.env.NEXT_PUBLIC_CDN_LINK}AboutUs/fold4/investors_img2.png`,
  aboutUsCareerBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}AboutUs/last+fold+/joinus_img.jpg`,
  //FAQS

  askQuestion: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/ask_a_ques_banner.png`,
  askQuestionButton: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/ask_ques.png`,

  // Customers
  deendayalThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/TurnoLive/png/deenadayal.png`,
  girishThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/TurnoLive/png/girish.png`,
  kambleThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/TurnoLive/png/kamble.png`,
  rudhreshThumbnail: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/TurnoLive/png/rudhresh.png`,

  playGameBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}PlayGame/game_banner.png`,

  // Single page Experiments
  carouselBanner1: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/png/banner1.webp`,
  carouselBanner2: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/png/banner2.webp`,
  carouselBanner3: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/png/banner3.webp`,
  carouselBanner4: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/png/banner4.png`,
  carouselBanner5: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/png/banner5.png`,
  carouselBanner6: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/png/banner6.webp`,

  ETRIO: `${process.env.NEXT_PUBLIC_CDN_LINK}thumbnail/vehicle/Etrio.webp`,
  mahindra: `${process.env.NEXT_PUBLIC_CDN_LINK}thumbnail/vehicle/Mahindra.webp`,
  OSM: `${process.env.NEXT_PUBLIC_CDN_LINK}thumbnail/vehicle/Osm.webp`,
  piaggio: `${process.env.NEXT_PUBLIC_CDN_LINK}thumbnail/vehicle/Piaggio.webp`,

  bookingOfferApplied: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/png/bookin_offer_applied.png`,
  money_icon: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/png/money_icon.png`,
  turno_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}SinglePageWebsite/png/turno_logo.png`,
  view_360: `${process.env.NEXT_PUBLIC_CDN_LINK}360DegreeView/360_view.png`,

  // Product Rating Carousel
  productRating1: `${process.env.NEXT_PUBLIC_CDN_LINK}product_ratings_corousel/webp/PRR1.webp`,
  productRating2: `${process.env.NEXT_PUBLIC_CDN_LINK}product_ratings_corousel/webp/PRR2.webp`,
  productRating3: `${process.env.NEXT_PUBLIC_CDN_LINK}product_ratings_corousel/webp/PRR3.webp`,

  charging: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/charging.png`,

  // Brand

  // Why Turno
  whyTurno1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whyturno1.jpg`,
  whyTurno2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whyturno2.jpg`,
  whyTurno3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whyturno3.jpg`,
  whyTurno4: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whyturno4.jpg`,

  bannerWhyTurno: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/banner.jpg`,
  ourPromiseBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/our_promise_banner.png`,
  aboutTurno: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/aboutturno.jpg`,

  // Customer Reviews
  review1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/review1.jpg`,
  review2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/review2.jpg`,
  review3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/review3.jpg`,

  //blogs
  blogsBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blogBanner.png`,
  blog1: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog1.jpg`,
  blog2: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog2.jpg`,
  blog3: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog3.jpg`,
  blog4: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog4.jpg`,
  blog5: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog5.jpg`,
  blog6: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog6.jpg`,
  blog7: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog7.jpg`,
  blog8: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog8.jpg`,

  blog10: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog10.jpg`,
  blog11: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog11.jpg`,
  blog12: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog12.jpg`,
  blog13: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog13.jpg`,
  blog14: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog14.jpg`,
  blog16: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/blog16.jpg`,

  noImage: `${process.env.NEXT_PUBLIC_CDN_LINK}blog/no-image-blog.jpg`,

  // Customer App Banner
  customerAppBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/customerapp_banner.png`,

  // Turno Operational Cities
  turnoOperationalBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/turno_operationalcities.jpg`,

  // TCO
  singleCoin: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/coin_electric.png`,
  doubleCoin: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/coin_cng.png`,
  tripleCoin: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/coin_diesel.png`,
  electricVehicle: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/electric_vehicle.png`,
  dieselVehicle: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/diesel_vehicle.png`,
  cngVehicle: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/cng_vehicle.png`,
  turnoBenefits: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/turno_benefits.png`,

  // emoji

  emoji1: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/png/1.png`,
  emoji2: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/png/2.png`,
  emoji3: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/png/3.png`,
  emoji4: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/png/4.png`,
  emoji5: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/png/5.png`,
  emoji6: `${process.env.NEXT_PUBLIC_CDN_LINK}tco/emoji/png/6.png`,

  // Brand Mutual Fund

  benefits1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/benefit1.png`,
  benefits2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/benefit2.png`,
  benefits3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/benefit3.png`,
  expert: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/Expert.png`,
  whatsappGIF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/whatsapp.gif`,
  savingsFlowerGIF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/savingsflower.gif`,
  batteryGIF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/battery.gif`,
  story1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/Turno_Post.mp4`,
  story2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/Turno_Post_2.mp4`,
  story3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/Turno_Post_3.mp4`,

  desktopCarouselBanner1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageBannersDesktop/banner1.webp`,
  desktopCarouselBanner2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageBannersDesktop/banner2.webp`,
  desktopCarouselBanner3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageBannersDesktop/banner3.webp`,

  // Dont Buy EV

  disadvantage1: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/fold_1_illustration.png`,
  disadvantage2: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/fold_2_illustration.png`,
  disadvantage3: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/fold_3_illustration.png`,
  batteryBuyBack: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/battery_buyback.png`,
  lowestIR: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/lowest_ir.png`,
  paperLess: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/paperless.png`,
  customerSupport: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/cust_support.png`,
  warningGif: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/error_exclamation.gif`,
  dontBuyEVStory: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/Dont_Buy_Fuel_KA.mp4`,

  // Finance

  chennaiOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/chennai.png`,
  delhiOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/delhi.webp`,
  gujratOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/gujarat.webp`,
  karnatakaOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/karnataka.webp`,
  maharashtraOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/maharashtra.webp`,
  telenganaOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/telangana.webp`,
  tamilNaduOperationalCity: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/tamil_nadu.webp`,

  // Banner Mobile

  bannerMobileFinance1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/banner1.jpg`,
  bannerMobileFinance2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/banner2.webp`,
  bannerMobileFinance3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/banner3.jpg`,

  // Banner Desktop

  bannerDesktopFinance1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/banner1.jpg`,
  bannerDesktopFinance2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/banner2.webp`,
  bannerDesktopFinance3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/banner3.jpg`,

  desktopCarouselBanner4: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageBannersDesktop/banner4.webp`,

  // Thumbnail Mobile

  thumbnailMobile1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/1.png`,
  thumbnailMobile2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/2.png`,
  thumbnailMobile3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/3.png`,

  // Thumbnail Desktop

  thumbnailDesktop1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/1.jpg`,
  thumbnailDesktop2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/2.jpg`,
  thumbnailDesktop3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/3.jpg`,
  thumbnailDesktop4: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/4.jpg`,
  thumbnailDesktop5: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/5.jpg`,
  girishCustomer: `${process.env.NEXT_PUBLIC_CDN_LINK}DontBuyEV/girish.png`,
  emiCustomerDesktop: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Desktop/emi_illustration.webp`,
  emiCustomerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/Mobile/emi_illustration.jpg`,

  // Review Video
  reviewVideo1: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo1.mp4`,
  reviewVideo2: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo2.mp4`,
  reviewVideo3: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo3.mp4`,
  reviewVideo4: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo4.mp4`,
  reviewVideo5: `${process.env.NEXT_PUBLIC_CDN_LINK}Finance/reviews/reviewVideo5.mp4`,

  googlePlay: `${process.env.NEXT_PUBLIC_CDN_LINK}globals/playstore_img.png`,

  // brand
  bookTestDrive: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/book_TD_banner.jpg`,
  allBrands: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/brands.jpg`,
  reviewBrand1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/reviews1.jpg`,
  reviewBrand2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/reviews2.jpg`,
  reviewBrand3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/reviews3.jpg`,
  brandSwiper1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/fold1_banner_interestrate.jpg`,
  brandSwiper2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/fold1_banner_resale.jpg`,
  brandSwiper3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/fold1_banner_TD.jpg`,
  ourCustomers: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/our_customers.png`,
  foldOne: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/fold1_icon.png`,
  vehicleImage: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/compressed/mahindra.jpg`,
  // vehicleImage: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/mahindra-charging-ps.gif`,

  brandExp3Video: `${process.env.NEXT_PUBLIC_CDN_LINK}brand3/brandExp3.mp4`,

  // Lending Partners

  ghanaBhansali: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/ghalabansali.png`,
  urgoCapitals: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/urgocapital.png`,
  shivalik: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/shivaliknbfc.png`,
  idfc: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/idfc.svg`,
  arthmate: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/arthmate.png`,
  dmiFinance: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/dmiFinanceLogo.svg`,
  vcpl: `${process.env.NEXT_PUBLIC_CDN_LINK}LendingPartners/vcpl.svg`,

  // web stories

  webStory1: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/1-min.webp`,
  webStory2: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/2-min.webp`,
  webStory3: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/3-min.webp`,
  webStory4: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/4-min.webp`,
  webStory5: `${process.env.NEXT_PUBLIC_CDN_LINK}web-stories/5-min.webp`,

  // New Design Images
  vehicleLoan: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/EMI_banner.webp`,
  testDrive: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/TD_banner.webp`,

  turnoCallAgent: `${process.env.NEXT_PUBLIC_CDN_LINK}revamp/turno+call.webp`,
};
